<template>
  <div class="Jiaocai">
    <div class="list pd40">
      <div class="container">
        <div class="headTit">
          <h3>Official <span>Textbooks</span></h3>
        </div>
        <div v-for="(item,index) in lists"
             :key="index">
          <div class="tit">{{item.title}}</div>
          <el-row :gutter="24">
            <el-col :span="8"
                    v-for="(item2,index) in item.lists"
                    :key="index">
              <div class="lis">
                <div class="imgbox">
                  <img :src="item2.img"
                       alt="">
                </div>
                <div class="text">
                  <p>
                    <b>Name:</b> {{item2.name}}
                  </p>
                  <p>
                    <b>Price:</b> {{item2.price}}
                  </p>
                  <p>
                    <b>Author:</b> {{item2.author}}
                  </p>
                  <p>
                    <b>Press:</b> {{item2.press}}
                  </p>
                  <p>
                    <b>ISNB:</b> {{item2.ISNB}}
                  </p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      lists: [{
        title: 'Business Chinese',
        lists: [{
          img: require('@/assets/book1.jpg'),
          name: '《BCTSTANDARD COURSE 1》',
          price: '￥49',
          author: 'Confucius Institute Headquarters/Hanban',
          press: "People's Education Press",
          ISNB: '9787107296857'
        }, {
          img: require('@/assets/book2.jpg'),
          name: '《BCTSTANDARD COURSE 2》',
          price: '￥49',
          author: 'Confucius Institute Headquarters/Hanban',
          press: "People's Education Press",
          ISNB: '9787107244292'
        }, {
          img: require('@/assets/book3.jpg'),
          name: '《BCTSTANDARD COURSE 3》',
          price: '￥49',
          author: 'Confucius Institute Headquarters/Hanban',
          press: "People's Education Press",
          ISNB: '9787107307928'
        }, {
          img: require('@/assets/book4.jpg'),
          name: '《Business Chinese Test Syllabus A》',
          price: '￥69',
          author: 'Confucius Institute Headquarters/Hanban',
          press: 'Higher Education Press',
          ISNB: '9787040343908'
        }, {
          img: require('@/assets/book5.jpg'),
          name: '《Business Chinese Test Syllabus B》',
          price: '￥69',
          author: 'Confucius Institute Headquarters/Hanban',
          press: 'Higher Education Press',
          ISNB: '9787040411843'
        }, {
          img: require('@/assets/book6.jpg'),
          name: '《Business chinese Language abilit Standards》',
          price: '￥79',
          author: 'Confucius Institute Headquarters/Hanban',
          press: 'Higher Education Press',
          ISNB: '9787040389531'
        }, {
          img: require('@/assets/book7.jpg'),
          name: '《OFFICIAL EXAMINATION PAPERS OF BUSINESS CHINESE TEST A》',
          price: '￥89',
          author: 'Confucius Institute Headquarters/Hanban',
          press: "People's Education Press",
          ISNB: '9787107329685'
        }, {
          img: require('@/assets/book8.jpg'),
          name: '《OFFICIAL EXAMINATION PAPERS OF BUSINESS CHINESE TEST B》',
          price: '￥89',
          author: 'Confucius Institute Headquarters/Hanban',
          press: "People's Education Press",
          ISNB: '9787107329678'
        }]
      },
      {
        title: 'Chinese proficiency standard course',
        lists: [{
          img: require('@/assets/book9.jpg'),
          name: '《HSK STANDARD COURSE 1》',
          price: '￥49',
          author: 'Confucius Institute Headquarters/Hanban',
          press: "People's Education Press",
          ISNB: '9787107296857'
        }, {
          img: require('@/assets/book10.jpg'),
          name: '《HSK STANDARD COURSE 2》',
          price: '￥49',
          author: 'Confucius Institute Headquarters/Hanban',
          press: "People's Education Press",
          ISNB: '9787107244292'
        }, {
          img: require('@/assets/book11.jpg'),
          name: '《HSK STANDARD COURSE 3》',
          price: '￥49',
          author: 'Confucius Institute Headquarters/Hanban',
          press: "People's Education Press",
          ISNB: '9787107307928'
        }, {
          img: require('@/assets/book12.jpg'),
          name: '《HSK STANDARD COURSE 4A》',
          price: '￥49',
          author: 'Confucius Institute Headquarters/Hanban',
          press: "People's Education Press",
          ISNB: '9787107307928'
        }, {
          img: require('@/assets/book13.jpg'),
          name: '《HSK STANDARD COURSE 4B》',
          price: '￥49',
          author: 'Confucius Institute Headquarters/Hanban',
          press: "People's Education Press",
          ISNB: '9787107307928'
        }, {
          img: require('@/assets/book14.jpg'),
          name: '《HSK STANDARD COURSE 5A》',
          price: '￥49',
          author: 'Confucius Institute Headquarters/Hanban',
          press: "People's Education Press",
          ISNB: '9787107307928'
        }, {
          img: require('@/assets/book15.jpg'),
          name: '《HSK STANDARD COURSE 5B》',
          price: '￥49',
          author: 'Confucius Institute Headquarters/Hanban',
          press: "People's Education Press",
          ISNB: '9787107307928'
        }, {
          img: require('@/assets/book16.jpg'),
          name: '《HSK TEXT SYLLABUS 1》',
          price: '￥69',
          author: 'Confucius Institute Headquarters/Hanban',
          press: "People's Education Press",
          ISNB: '9787107304187'
        }, {
          img: require('@/assets/book17.jpg'),
          name: '《HSK TEXT SYLLABUS 2》',
          price: '￥69',
          author: 'Confucius Institute Headquarters/Hanban',
          press: "People's Education Press",
          ISNB: '9787107304194'
        }, {
          img: require('@/assets/book18.jpg'),
          name: '《HSK TEXT SYLLABUS 3》',
          price: '￥69',
          author: 'Confucius Institute Headquarters/Hanban',
          press: "People's Education Press",
          ISNB: '9787107304200'
        }, {
          img: require('@/assets/book19.jpg'),
          name: '《HSK TEXT SYLLABUS 4》',
          price: '￥69',
          author: 'Confucius Institute Headquarters/Hanban',
          press: "People's Education Press",
          ISNB: '9787107304217'
        }, {
          img: require('@/assets/book20.jpg'),
          name: '《HSK TEXT SYLLABUS 5》',
          price: '￥69',
          author: 'Confucius Institute Headquarters/Hanban',
          press: "People's Education Press",
          ISNB: '9787107304224'
        }, {
          img: require('@/assets/book21.jpg'),
          name: '《HSK TEXT SYLLABUS 6》',
          price: '￥69',
          author: 'Confucius Institute Headquarters/Hanban',
          press: "People's Education Press",
          ISNB: '9787107304873'
        }]
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/banner.png) center center no-repeat;
  height: 300px;
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.list {
  .lis {
    border: 1px solid #dedede;
    padding: 10px;
    margin-bottom: 20px;
    .text {
      p {
        margin-top: 10px;
        font-size: 14px;
        line-height: 1;
        color: #545454;
      }
    }
  }
}
.tit {
  border-left: 8px solid #0c3879;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 30px;
  line-height: 1;
}
</style>